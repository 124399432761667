/* Common Style */
.btn:active,
.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn:disabled {
  color: #FFFFFF;
  background-color: #193B3C;
  border: 2px solid #193B3C;
  opacity: unset
}

.btn {
  min-width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 25px;
}

.btn-submit:hover,
.navbar-nav .btn-submit.nav-link.show {
  background-color: transparent;
  color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-submit {
  color: #FFFFFF;
  background-color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-submit:hover,
.btn.btn-submit:active {
  background-color: transparent;
  color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-secondary {
  color: #FFFFFF;
  background-color: #C3997D;
  border: 2px solid #C3997D;
}

.btn-secondary:hover,
.btn.btn-secondary:active {
  background-color: transparent;
  color: #C3997D;
  border: 2px solid #C3997D;
}

.content-box-style {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 50px;
  z-index: 10000;
  overflow: hidden;
}

.content-box-style:before {
  height: 261px;
  width: 265px;
  position: absolute;
  content: "";
  bottom: -20px;
  left: -25px;
  /* background-image: url(../images/form-bg-vector.png); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.content-box-style h4 {
  font-size: 24px;
  font-weight: 700;
  color: #464646;
}

button.navbar-toggler {
  border-color: #FFFFFF;
}

button.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.navbar-toggler-icon {
  background-image: url(../icons/toggle-menu-icon.svg);
}

/* Header Style */
.navshadow-style {
  border-bottom: 1px solid rgba(236, 236, 236, 0.5);
  z-index: 99;
}

.cta-btn {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cta-btn svg {
  height: 50px;
  width: 50px;
  fill: #FFFFFF;
}

.cta-btn .icon {
  height: 50px;
  width: 50px;
  fill: #FFFFFF;
}

.navbar.sticky-navbar .cta-btn svg {
  fill: #193B3C;
}

.navbar.sticky-navbar .cta-btn .icon {
  fill: #193B3C;
}

.cta-btn-content {
  display: flex;
  flex-direction: column;
}

.cta-btn-content span {
  font-size: 15px;
  color: #FFFFFF;
}

.cta-btn-content a {
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
}

.navbar.sticky-navbar .cta-btn-content span,
.navbar.sticky-navbar .cta-btn-content a {
  color: #193B3C;
}


.navbar {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: transparent;
  z-index: 9999;
}

.navbar.sticky-navbar {
  background-color: #FFFFFF;
}

.navbar a .sticky-logo {
  display: none;
}

.navbar.sticky-navbar a .default-logo {
  display: none;
}

.navbar.sticky-navbar a .sticky-logo {
  display: block;
}

/* Body Style */
.bg-style {
  min-height: 100vh;
  background-image: url(../images/advancements-clean-energy-technologies-generative-ai.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-style:before {
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(25, 59, 59, 0.82);
}

.page-content {
  display: flex;
  align-items: center;
  padding: 130px 0 50px;
  position: absolute;
  top: 0px;
}

.form-style {
  z-index: 2;
}

.form-style .field-style span,
.form-style .field-style input {
  height: 60px;
  padding: 12px 20px;
}

.form-style .field-style span {
  background-color: transparent;
  border-right: 0px;
  position: relative;
  z-index: 1;
}

.form-style .field-style span svg {
  height: 25px;
  width: 25px;
  fill: #193B3C;
}

.form-style .field-style span img {
  height: 25px;
  width: 25px;
  fill: #193B3C;
}

.form-style .field-style span::after {
  height: 20px;
  width: 1px;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  background-color: #dee2e6;
  z-index: 2;
}

.form-style .field-style input {
  border-left: 0px;
  background-color: transparent;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.search-dropdown {
  width: 10rem;
  z-index: 2;
}

/* .form-style .field-style .country-select {
  border-left: 0px;
  border-right: 0px;
  background-color: transparent;
}

.form-style .field-style .country-select::after {
  height: 20px;
  width: 1px;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  background-color: #dee2e6;
  z-index: 2;
} */

.form-style .field-style input.password {
  border-right: 0px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-style .field-style input:focus {
  outline: none;
  box-shadow: none;
  border-color: #dee2e6;
}

.form-style .field-style a.show-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.form-style .field-style span.show-icon {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-left: 0px;
  position: unset;
  z-index: 1;
  border-right: 1px solid #dee2e6;
}

.form-style .field-style span img {
  cursor: pointer;
}

.svg-icon-size {
  height: 25px;
  width: 25px;
}

.otp-inputs input {
  width: 54px !important;
  text-align: center !important;
  border: 1px solid #ced4da;
  border-radius: 8px;
  height: 54px;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  margin-right: auto
}

.otp-inputs label.form-label.text-primary {
  text-align: center;
  margin: 20px auto !important;
  width: 100%;
  color: #fc1321eb !important;
}

.sunset-input-active {
  border: none !important;
  box-shadow: 0 0 16px #dddddd !important;
}

.input-last-icons {
  top: -40px;
  left: -20px;
}

.my-token-tabs .nav button.active {
  color: #00a14b !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.carousel-indicators button {
  background-color: #d8d8d8 !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
}

.carousel-indicators button.active {
  background-color: #00db16 !important;
}

.news-headlines {
  margin: -160px 15px 0% 1%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

.news-headlines div.card-body {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

.cursor-pointer:hover {
  cursor: pointer;
}

.notification {
  padding: 10px 20px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  margin-top: -40px;
}

.page-item {
  margin-left: 20px !important;
}

.input-icon {
  background: initial;
  border-right: none;
  border: 1px solid #e3e6e9;
}

.input-icon+input {
  border-left: none;
}

.info-icon {
  height: 15px;
  margin-left: 2%;
}

.otp-all-input {
  height: 50px;
  width: 50px;
  margin: 0px 15px 20px 0px;
  border-radius: 4px;
  background-color: #f3f5f6;
  border: none;
  /* padding-left: 30%; */
  font-size: 16px !important;
  color: black;
}

/* .active {
  background: #ffffff !important;
  color: #fff !important;
  border-radius: 4px !important;
} */

.header-main .active {
  background: rgb(196, 151, 124) !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.modal-size {
  width: 50%;
  height: 50%;
}

.shadow-partner {
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
}

.button-green {
  background-color: #39e388;
}

.button-brown {
  background-color: #c4977c;
}

.modal-90w {
  max-width: 90% !important;
  height: 90% !important;
}

#set-dd {
  color: black;
  border: none;
  box-shadow: none;
  background-color: #f3f5f6;
  border-radius: 5px;
  width: 100%;
  height: 40px;
}

.dropdown-img {
  width: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.Loader2 {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.dropdown-options {
  width: 250px;
  max-height: 300px;
  overflow-y: scroll;
}

.list-style-none {
  list-style-type: none;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}