.alert {
  width: 30%;
  border-radius: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
  border-bottom-width: 7px !important;
}

.success-alert {
  border: 1px solid #6CE3A4;
  color: #00A14B;
}

.error-alert {
  border: 1px solid #FC8F86;
  color: #FC8F86;
}

/* for sm screen */
@media (min-width: 50px) and (max-width: 575px) {
  .alert {
    width: 75%;
  }
}

/* for sm screen */
@media (min-width: 576px) and (max-width: 767px) {
  .alert {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .alert {
    width: 50%;
  }
}