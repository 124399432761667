body {
  font-family: 'Poppins', sans-serif;
  background-color: #ededed;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.sunset-yellow {
  color: #fad702;
}

.w-20 {
  width: 20%;
}

.sunset-yellow-bg {
  background-color: #fad702;
}

.sunset-F3F5F6-bg {
  background-color: #f3f5f6;
}

.sunset-F2F2F2-bg {
  background-color: #f2f2f2;
}

.sunset-ffffff-bg {
  background-color: #fff;
}

.font-FBBC04 {
  color: #fbbc04;
}

.sunset-00A14B-bg {
  background-color: #00a14b;
}

.sunset-C4977C-bg {
  background-color: #c4977c;
}

.sunset-6CE3A4-bg {
  background-color: #6ce3a4;
}

.sunset-39E38833-bg {
  background: #39e38833;
}

.sunset-E5E5E5-bg {
  background: #e5e5e5;
}

.sunset-FFEE50-bg {
  background: #ffee50;
}

.sunset-FFEE50-bg {
  background: #ffee50;
}

.sunset-D8D8D8-bg {
  background-color: #d8d8d8;
}

.sunset-C5997C-bg {
  background-color: #c5997c;
}

.sunset-34A853-bg {
  background-color: #34a853;
}

.sunset-F89A34-bg {
  background-color: #f89a34;
}

.sunset-000000-bg {
  background-color: #000000;
}

.sunset-193B3C-bg {
  background-color: #193b3c;
}

.border-radidus-4 {
  border-radius: 4px;
}

.border-radidus-8 {
  border-radius: 8px;
}

.border-radidus-10 {
  border-radius: 10px;
}

.border-radidus-12 {
  border-radius: 12px;
}

.border-radidus-16 {
  border-radius: 16px;
}

.border-radidus-24 {
  border-radius: 24px;
}

.border-radidus-50 {
  border-radius: 50px;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-18 {
  font-size: 18px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-36 {
  font-size: 36px;
}

.font-42 {
  font-size: 42px;
}

.font-38 {
  font-size: 38px;
}

.font-48 {
  font-size: 48px;
}

.font-30 {
  font-size: 30px !important;
}

.font-24 {
  font-size: 24px;
}

.font-20 {
  font-size: 20px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-8 {
  font-size: 8px;
}

.font-6E757C {
  color: #6e757c;
}

.font-DC4437 {
  color: #dc4437;
}

.font-272E35 {
  color: #272e35;
}

.font-919191 {
  color: #919191;
}

.font-848F9F {
  color: #848f9f;
}

.font-656565 {
  color: #656565;
}

.font-909090 {
  color: #909090;
}

.font-00A14B {
  color: #00a14b;
}

.font-C5997C {
  color: #c5997c;
}

.font-34A853 {
  color: #34a853;
}

.font-5F738C {
  color: #5f738c;
}

.text-green {
  color: #193B3C;
}

.font-ffffff,
.font-ffffff:hover {
  color: #fff;
  text-decoration: none;
}

.font-292930 {
  color: #292930;
}

.font-999FAE {
  color: #999fae;
}

.font-000000 {
  color: #000000;
}

.font-bg-ffc107 {
  background-color: #ffc107;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-45 {
  width: 45%;
}

.h-45 {
  height: 45%;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

.w-fit-content {
  width: fit-content;
}

.border-left-2-C4C4C4 {
  border-left: 2px solid #c4c4c4;
}

.border-right-2-C4C4C4 {
  border-right: 2px solid #c4c4c4;
}

.sunset-input {
  border: 1px solid #e3e6e9;
}

.border-2-00A14B {
  border: 2px solid #00a14b;
}

.border-2-909090 {
  border: 2px solid #909090;
}

i:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: initial;
}

a:hover {
  text-decoration: none;
  color: initial;
}