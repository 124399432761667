@media only screen and (max-width: 991px) {
  .cta-btn {
    justify-content: center;
    background-color: #a9a9a9;
    padding: 15px;
    border-radius: 10px;
  }

  .dropdown-toggle span {
    font-size: 0px;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .nav-link.dropdown-toggle {
    padding: 10px 15px 10px 10px;
  }

  .content-box-style {
    padding: 30px 20px;
  }
}